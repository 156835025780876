/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { createBrowserHistory } from 'history';
import { Row, ButtonGoBack } from '~/components';
import { parseToQueryString } from '~/modules/Helper/querystring-helper';

import { TIPO_VISAO } from '~/modules/Helper/tipo-visao';
import { MODO_VISAO } from '~/modules/Helper/modoVisao';
import { NUMERO_QUANTIDADE_REGISTROS } from '~/modules/Helper/numeroQuantidadeRegistros';
import { PARAMS_FILTERS_GRID } from '~/modules/Helper/searchParamsFilters';
import { getAndFormatSearchParams } from '~/modules/Helper/searchString-helper';
import { useUrls } from '~/contexts/url.context';
import * as ModuloVisaoService from './services/moduloVisao.service';

import Header from './components/Grid/Header.component';
import Totalizadores from './components/Totalizadores/Totalizadores.component';
import TableDinamic from './components/Grid/TableDinamic.component';
import FiltroPeriodicidadeModuloDinamico from './components/Grid/FiltroPeriodicidadeModuloDinamico.component';

import { useDebounce } from '../Helper/hooks/debounce.hook';

import { FILTRO_DEFAULT } from '../Helper/FiltrosPeriodicidade';

function ModuloDinamicoPage({ match, history }) {
  const browserHistory = createBrowserHistory();

  const { empilhaUrl, obterUrlPaginaAnterior } = useUrls();

  const routeParams = new URLSearchParams(history.location.search);

  const periodicidadeParam = routeParams.get('periodicidade')?.toUpperCase();
  const periodoParam = routeParams.get('periodo')?.toUpperCase();
  const exercicioParam = Number(routeParams.get('exercicio'));
  const paginacaoGridParam = Number(routeParams.get('pagina'));
  const quantidadeRegistrosParam = routeParams.get('quantidaderegistros');

  const valorFiltroVisaoAgrupamentoParam = routeParams.get(
    'valorfiltrovisaoagrupamento'
  );

  const colunaFiltroVisaoAgrupamentoParam = routeParams.get(
    'colunafiltrovisaoagrupamento'
  );

  const tipoValorFiltroVisaoAgrupamentoParam = routeParams.get(
    'tipovalorfiltrovisaoagrupamento'
  );

  const listaColunaOrdemParam = JSON.parse(routeParams.get('listacolunaordem'));

  const [dataUltimaAtualizacao, setDataUltimaAtualizacao] = useState(null);
  const [configuracao, setConfiguracao] = useState(null);
  const [listaPeriodicidades, setListaPeriodicidades] = useState([]);
  const [listaPeriodos, setListaPeriodos] = useState([]);
  const [listaExercicios, setListaExercicios] = useState([]);
  const [possuiArquivoDigital, setPossuiArquivoDigital] = useState(false);
  const [moduloVisao, setModuloVisao] = useState(null);
  const [urlVisaoAnalitica, setUrlVisaoAnalitica] = useState(null);
  const [periodoDesabilitado, setPeriodoDesabilitado] = useState(false);
  const [InputCarregando, setInputCarregando] = useState(false);
  const [filtro, setFiltro] = useState([]);
  const filtroDebounce = useDebounce(filtro, 500);
  const [totalizadores, setTotalizadores] = useState([]);
  const [filtroDefault, setFiltroDefaultEnum] = useState();

  const [dataFilterHeader, setDataFilterHeader] = useState([]);

  // filtros da visão agrupamento
  const [
    colunaFiltroVisaoAgrupamento,
    setColunaFiltroVisaoAgrupamento,
  ] = useState(null);
  const [
    valorFiltroVisaoAgrupamento,
    setValorFiltroVisaoAgrupamento,
  ] = useState(null);
  const [
    tipoValorFiltroVisaoAgrupamento,
    setTipoValorFiltroVisaoAgrupamento,
  ] = useState(null);

  const [periodicidadeSelecionada, setPeriocidadeSelecionada] = useState(null);
  const [periodoSelecionado, setPeriodoSelecionado] = useState(null);
  const [exercicioSelecionado, setExercicioSelecionado] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [quantidadeRegistros, setQuantidadeRegistros] = useState();
  const [listaColunaOrdem, setListaColunaOrdem] = useState([]);
  const [tableKey, setTableKey] = useState(Date.now());
  const [exibirArquivosForm, setExibirArquivosForm] = useState(false);
  const [
    filtroPesquisaArquivosDigitais,
    setFiltroPesquisaArquivosDigitais,
  ] = useState(null);
  const [isFiltroAvancadoHabilitado, setIsFiltroAvancadoHabiltado] = useState(
    false
  );

  const { modulo, visao } = match.params;
  const [chavesPeriodicidade, setChavesPeriodicidade] = useState([]);

  const obterVisaoColunas = propriedade => {
    return (
      configuracao?.VisaoColunas?.find(
        x => x.OcultoGridPortal !== true && x[propriedade] // A coluna OcultoGridPortal não deve ser utilizada
      ) || null
    );
  };

  const obterColunaChave = useCallback(() => {
    return (
      obterVisaoColunas('Chave') ||
      obterVisaoColunas('ChaveUnicaModulo') ||
      obterVisaoColunas('ColunaLink') ||
      configuracao?.VisaoColunas?.[0] ||
      null
    );
  }, [configuracao]);

  const obterChaveUnicaModulo = useCallback(() => {
    return obterVisaoColunas('ChaveUnicaModulo') || obterColunaChave();
  }, [configuracao, obterColunaChave]);

  const obterColunaLink = useCallback(() => {
    return obterVisaoColunas('ColunaLink') || obterChaveUnicaModulo();
  }, [configuracao, obterChaveUnicaModulo]);

  const obterOrdenacaoPadrao = useCallback(
    colunaChave => {
      const ordenacoes = configuracao.VisaoColunas?.map(item => {
        if (item.Ordenacao)
          return {
            ColunaOrdem: item.FonteDados,
            TipoOrdem: item.Ordenacao.TipoOrdenacao,
            Ordem: item.Ordenacao.Ordem,
          };
        return null;
      })?.filter(Boolean);

      if (ordenacoes?.length) setListaColunaOrdem(ordenacoes);
      else
        setListaColunaOrdem([
          {
            ColunaOrdem: colunaChave.FonteDados,
            TipoOrdem: 'ascend',
            Ordem: 1,
          },
        ]);

      setTableKey(Date.now());
    },
    [configuracao]
  );

  function handleTableChange(pagination, filters, sorter) {
    if (!Array.isArray(sorter))
      return setListaColunaOrdem([
        {
          ColunaOrdem: sorter.field,
          TipoOrdem: sorter.order,
          Ordem: 1,
        },
      ]);
    const maxOrdem = Math.max(...listaColunaOrdem?.map(item => item.Ordem));

    const novaListaOrdenacaoColuna = sorter?.map((s, index) => {
      const itemOrdenacao = listaColunaOrdem.find(
        (ordenacao, idx) =>
          s.columnKey === ordenacao.ColunaOrdem && index === idx
      );
      if (itemOrdenacao) {
        return {
          ...itemOrdenacao,
          TipoOrdem: s.order,
        };
      }
      return {
        ColunaOrdem: s.field,
        TipoOrdem: s.order,
        Ordem: maxOrdem ? maxOrdem + 1 : 1,
      };
    });

    setListaColunaOrdem(novaListaOrdenacaoColuna);
  }

  function obterValoresChavesPeriodicidadePorLinha(linhaGrid) {
    const valores = chavesPeriodicidade.map(colunaGrid => ({
      campo: colunaGrid.FonteDados,
      valor: linhaGrid[colunaGrid.FonteDados],
      tipo: colunaGrid.TipoValor,
    }));
    return valores;
  }

  const exibirAnexos = useCallback(row => {
    const colunasChave = configuracao?.VisaoColunas.filter(
      colunaGrid => colunaGrid.ChaveUnicaModulo
    );

    const chavePesquisa = {
      ChaveModulo: modulo,
      Chave: colunasChave.map(coluna => ({
        Campo: coluna.FonteDados,
        Valor: row[coluna.FonteDados],
        TipoValor: coluna.TipoValor,
      })),
    };

    setFiltroPesquisaArquivosDigitais(chavePesquisa);
    setExibirArquivosForm(true);
  });

  const validaPeriodicidade = useCallback(() => {
    if (!periodicidadeSelecionada) return false;

    const periodoAnualValido =
      periodicidadeSelecionada === 'ANUAL' && !periodoSelecionado;

    let periodoValidoParaPeriodicidade;
    if (periodicidadeSelecionada === null) {
      periodoValidoParaPeriodicidade = listaPeriodicidades;
    } else {
      periodoValidoParaPeriodicidade = listaPeriodicidades.some(
        p =>
          p.Id === periodicidadeSelecionada &&
          p.Periodos &&
          p.Periodos.length &&
          p.Periodos.some(x => x.Id === periodoSelecionado)
      );
    }

    return periodoAnualValido || periodoValidoParaPeriodicidade;
  }, [periodicidadeSelecionada, listaPeriodicidades, periodoSelecionado]);

  function redirecionarDetalheVisaoAnalitica(valoresChavesPeriodicidade) {
    let colunasPeriodicidadeCampoValor = {};

    valoresChavesPeriodicidade.forEach(item => {
      colunasPeriodicidadeCampoValor[item.campo] = item.valor;
    });

    const data = {
      exercicio: exercicioSelecionado,
      periodicidade: periodicidadeSelecionada,
      periodo: periodoSelecionado,

      ...colunasPeriodicidadeCampoValor,
    };

    const queryString = parseToQueryString(data);

    empilhaUrl(window.location.hash.split('#')[1]);

    history.push({
      pathname: `${urlVisaoAnalitica}/detalhevisao`,
      state: {
        pagina,
        quantidadeRegistros,
        colunaFiltroVisaoAgrupamento,
        valorFiltroVisaoAgrupamento,
        tipoValorFiltroVisaoAgrupamento,
        listaColunaOrdem,
        filtro,
      },
      search: queryString,
    });
  }

  function redirecionarVisaoAnalitica(
    colunaSelecionada,
    valorSelecionada,
    tipoValorColunaSelecionada
  ) {
    setColunaFiltroVisaoAgrupamento(colunaSelecionada);
    setValorFiltroVisaoAgrupamento(valorSelecionada);
    setTipoValorFiltroVisaoAgrupamento(tipoValorColunaSelecionada);

    empilhaUrl(window.location.hash.split('#')[1]);

    history.push({
      pathname: urlVisaoAnalitica,
      state: {
        colunaSelecionada,
        chaveSelecionada: valorSelecionada,
        tipoValorColunaSelecionada,
        exercicio: exercicioSelecionado,
        periodicidade: periodicidadeSelecionada,
        periodo: periodoSelecionado,
        quantidadeRegistros,
        valorFiltroVisaoAgrupamento,
        tipoValorFiltroVisaoAgrupamento,
        isFromAgrupamento: true,
        listacolunaordem: listaColunaOrdem
          ? JSON.stringify(listaColunaOrdem)
          : null,
      },
    });
  }

  function voltarPaginaAnterior() {
    if (configuracao.Tipo === TIPO_VISAO.AGRUPAMENTO) {
      history.push(`/`);
    }

    const { urlBase, queryParam } = obterUrlPaginaAnterior();

    if (urlBase && queryParam) {
      history.push({ pathname: urlBase, search: queryParam });
    } else {
      history.push(`/`);
    }
  }

  const buscaDadosGrid = useCallback(() => {
    if (!filtroDefault) return;

    if (
      filtroDefault !== FILTRO_DEFAULT.SEM_FILTRO &&
      (!validaPeriodicidade() || !exercicioSelecionado)
    ) {
      return;
    }

    if (!configuracao || !listaColunaOrdem?.length) {
      return;
    }
    const FiltroVisaoAgrupamento = {
      Campo: colunaFiltroVisaoAgrupamento,
      Valor: valorFiltroVisaoAgrupamento,
      TipoValor: tipoValorFiltroVisaoAgrupamento,
    };

    let mergeFiltro = [];
    let indexFiltro;
    if (dataFilterHeader?.Filtros && dataFilterHeader?.Filtros.length > 0) {
      mergeFiltro = dataFilterHeader.Filtros;

      if (filtroDebounce) {
        indexFiltro = mergeFiltro.findIndex(
          x => x.Campo == filtroDebounce[0]?.Campo
        );

        if (indexFiltro >= 0) {
          mergeFiltro = mergeFiltro.filter(
            item => item.Campo !== mergeFiltro[indexFiltro].Campo
          );
        }

        if (filtroDebounce.length > 0 && filtroDebounce[0].Valor !== '') {
          mergeFiltro.push(filtroDebounce[0]);
        }
      }

      if (filtroDebounce === -1 || filtroDebounce === undefined) {
        mergeFiltro = dataFilterHeader.Filtros;
      }
    } else {
      mergeFiltro =
        Array.isArray(dataFilterHeader?.Filtros) &&
        dataFilterHeader?.Filtros.length > 0
          ? dataFilterHeader?.Filtros
          : filtroDebounce;
    }

    let data = {
      ChaveModulo: modulo,
      NomeVisao: visao,
      Filtros: mergeFiltro,
      Periodicidade: periodicidadeSelecionada,
      Periodo: periodoSelecionado,
      Exercicio: exercicioSelecionado,
      Pagina: pagina,
      QuantidadeRegistros: quantidadeRegistros,
      Ordenacao: listaColunaOrdem,
      FiltroVisaoAgrupamento,
    };

    const dataQueryString = {
      periodicidade: periodicidadeSelecionada,
      periodo: periodoSelecionado,
      exercicio: exercicioSelecionado,
      pagina,
      quantidaderegistros: quantidadeRegistros,
      colunafiltrovisaoagrupamento: colunaFiltroVisaoAgrupamento,
      valorfiltrovisaoagrupamento: valorFiltroVisaoAgrupamento,
      tipovalorfiltrovisaoagrupamento: tipoValorFiltroVisaoAgrupamento,
      listacolunaordem: listaColunaOrdem
        ? JSON.stringify(listaColunaOrdem)
        : null,
    };

    const searchParams = getAndFormatSearchParams(
      dataQueryString,
      PARAMS_FILTERS_GRID
    );

    setDataFilterHeader(data);

    function filtrarModuloVisao(dataFiltro) {
      setInputCarregando(true);
      ModuloVisaoService.filtrar(dataFiltro)
        .then(res => {
          setModuloVisao(res.data);
          setInputCarregando(false);
        })
        .catch(() => {
          setModuloVisao({
            QuantidadePaginas: 0,
            QuantidadeRegistros: 0,
            Valores: [],
          });
          setInputCarregando(false);
        });
    }

    if (configuracao) {
      if (configuracao.ModoVisao === MODO_VISAO.GridVazia) {
        if (data?.Filtros.length > 0) {
          filtrarModuloVisao(data);
        } else {
          setModuloVisao({
            QuantidadePaginas: 0,
            QuantidadeRegistros: 0,
            Valores: [],
          });
        }
        return;
      }

      if (configuracao.ModoVisao === MODO_VISAO.Manutencao) {
        history.push(
          `/manutencao?visao=dinamico/${modulo}/${visao}&titulo=${configuracao.Titulo}`
        );
      } else filtrarModuloVisao(data);
    }

    if (!configuracao.ModoVisao === MODO_VISAO.Manutencao) {
      browserHistory.replace(
        `/#/dinamico/${modulo}/${visao}?${searchParams}`,
        history.location.state
      );
    }
  }, [
    valorFiltroVisaoAgrupamento,
    colunaFiltroVisaoAgrupamento,
    tipoValorFiltroVisaoAgrupamento,
    periodoDesabilitado,
    exercicioSelecionado,
    filtroDebounce,
    modulo,
    pagina,
    periodicidadeSelecionada,
    periodoSelecionado,
    quantidadeRegistros,
    validaPeriodicidade,
    visao,
    listaColunaOrdem,
    configuracao,
    filtroDefault,
  ]);

  const obterConfiguracoes = useCallback(async () => {
    const responseConfiguracao = await ModuloVisaoService.obterConfiguracoes(
      modulo,
      visao
    );

    if (responseConfiguracao?.data) setConfiguracao(responseConfiguracao.data);
  }, [modulo, visao]);

  const obterExerciciosEVisaoAnalitica = useCallback(async () => {
    const responseConfiguracoesGerais = await ModuloVisaoService.obterConfiguracoesGerais(
      modulo
    );

    if (responseConfiguracoesGerais?.data) {
      const {
        NomeVisaoAnalitica,
        Exercicios,
        PossuiArquivoDigital,
        DataUltimaSicronizacaoModulo,
        Periodicidades,
        FiltroHabilitado,
        FiltroDefaultEnum,
      } = responseConfiguracoesGerais?.data;

      if (NomeVisaoAnalitica) setUrlVisaoAnalitica(NomeVisaoAnalitica);

      if (DataUltimaSicronizacaoModulo)
        setDataUltimaAtualizacao(DataUltimaSicronizacaoModulo);

      if (FiltroHabilitado) setIsFiltroAvancadoHabiltado(FiltroHabilitado);

      if (FiltroDefaultEnum) setFiltroDefaultEnum(FiltroDefaultEnum);

      if (Exercicios?.length) {
        await setListaExercicios(Exercicios);

        const exercicioParamExisteArray = Exercicios.find(
          x => x.Exercicio === exercicioParam
        );

        await setExercicioSelecionado(
          exercicioParamExisteArray === undefined
            ? Exercicios[0].Exercicio
            : exercicioParam
        );
      }

      if (Periodicidades?.length) {
        await setListaPeriodicidades(Periodicidades);

        const periodicidadeDoParam = Periodicidades.find(
          x => x.Id === periodicidadeParam
        );

        await setPeriocidadeSelecionada(
          periodicidadeDoParam === undefined
            ? Periodicidades[0].Id
            : periodicidadeParam
        );
      }

      if (PossuiArquivoDigital !== null && PossuiArquivoDigital !== undefined)
        setPossuiArquivoDigital(PossuiArquivoDigital);
    }
  }, [modulo]);

  function setSearchParams() {
    setTipoValorFiltroVisaoAgrupamento(
      tipoValorFiltroVisaoAgrupamentoParam || null
    );
    setColunaFiltroVisaoAgrupamento(colunaFiltroVisaoAgrupamentoParam || null);
    setValorFiltroVisaoAgrupamento(valorFiltroVisaoAgrupamentoParam || null);
    setPeriodoSelecionado(periodoParam || null);
    setExercicioSelecionado(exercicioParam || null);
    setPagina(paginacaoGridParam || 1);
    setQuantidadeRegistros(
      NUMERO_QUANTIDADE_REGISTROS.includes(quantidadeRegistrosParam)
        ? quantidadeRegistrosParam
        : '10'
    );

    if (listaColunaOrdemParam?.length)
      setListaColunaOrdem(listaColunaOrdemParam);
  }

  function obterChavesPeriocidadeDaConfiguracao() {
    const colunasPeriodicidade = configuracao.ChavesPeriodicidades;

    setChavesPeriodicidade(arrayAnterior => [
      ...arrayAnterior,
      ...colunasPeriodicidade,
    ]);
  }

  const carregarPagina = useCallback(() => {
    if (visao !== urlVisaoAnalitica) {
      setColunaFiltroVisaoAgrupamento(null);
      setValorFiltroVisaoAgrupamento(null);
      setTipoValorFiltroVisaoAgrupamento(null);
      if (filtro?.length) {
        setFiltro([]);
      }
    } else if (history.location.state) {
      const {
        chaveSelecionada,
        colunaSelecionada,
        tipoValorColunaSelecionada,
        exercicio,
        periodicidade,
        periodo,
        tipoOrdem,
        isFromAgrupamento,
        // váriaveis com o mesmo nome já existem no escopo acima
        quantidadeRegistros: quantidadeRegistrosHistory,
        colunaFiltroVisaoAgrupamento: colunaFiltroVisaoAgrupamentoHistory,
        valorFiltroVisaoAgrupamento: valorFiltroVisaoAgrupamentoHistory,
        tipoValorFiltroVisaoAgrupamento: tipoValorFiltroVisaoAgrupamentoHistory,
        colunaOrdem: colunaOrdemHistory,
        filtro: filtroHistory,
      } = history.location.state;

      if (colunaFiltroVisaoAgrupamentoHistory) {
        setColunaFiltroVisaoAgrupamento(colunaFiltroVisaoAgrupamentoHistory);
      } else {
        setColunaFiltroVisaoAgrupamento(colunaSelecionada);
      }

      if (valorFiltroVisaoAgrupamentoHistory) {
        setValorFiltroVisaoAgrupamento(valorFiltroVisaoAgrupamentoHistory);
      } else if (isFromAgrupamento) {
        setValorFiltroVisaoAgrupamento(chaveSelecionada);
      }

      if (tipoValorFiltroVisaoAgrupamentoHistory) {
        setTipoValorFiltroVisaoAgrupamento(
          tipoValorFiltroVisaoAgrupamentoHistory
        );
      } else {
        setTipoValorFiltroVisaoAgrupamento(tipoValorColunaSelecionada);
      }

      setFiltro(filtroHistory);

      if (listaColunaOrdem && tipoOrdem) {
        setListaColunaOrdem([
          {
            ColunaOrdem: colunaOrdemHistory,
            TipoOrdem: tipoOrdem,
          },
        ]);
      }
      setExercicioSelecionado(exercicio);

      setPeriocidadeSelecionada(periodicidade);
      setPeriodoSelecionado(periodo);
      setQuantidadeRegistros(quantidadeRegistrosHistory || '10');

      // eslint-disable-next-line no-param-reassign
      history.location.state = null;
    }
  }, [history.location.state, urlVisaoAnalitica, visao]);

  function desabilitarPeriodo() {
    setPeriodoDesabilitado(true);
    setPeriodoSelecionado(null);
    carregarPagina();
  }

  function configuraPeriodoPorTipoPeriodicidade() {
    setPeriodoDesabilitado(false);
    const exercicio = listaExercicios.find(
      ex => ex.Exercicio === exercicioSelecionado
    );

    if (exercicio) {
      const { IdsPeriodicidadePeriodoUltimoMes } = exercicio;

      if (IdsPeriodicidadePeriodoUltimoMes?.length) {
        const IdPeriodicidadePeriodoSelecionado = IdsPeriodicidadePeriodoUltimoMes.find(
          p => p.IdPeriodicidade === periodicidadeSelecionada
        );
        if (IdPeriodicidadePeriodoSelecionado?.IdPeriodo) {
          const periodoValido = listaPeriodicidades.some(
            e =>
              e.Id === periodicidadeSelecionada &&
              e.Periodos.some(p => p.Id === periodoSelecionado)
          );
          setPeriodoSelecionado(
            periodoValido
              ? periodoSelecionado
              : IdPeriodicidadePeriodoSelecionado.IdPeriodo
          );
          carregarPagina();
        }
      }
    }
  }

  function configuraPeriodo() {
    if (periodicidadeSelecionada === 'ANUAL') {
      desabilitarPeriodo();
    } else if (
      listaExercicios?.length &&
      exercicioSelecionado &&
      periodicidadeSelecionada
    ) {
      configuraPeriodoPorTipoPeriodicidade();
    }
  }

  // set exercicios, exercicio e visao analitica
  useEffect(() => {
    obterExerciciosEVisaoAnalitica();
    obterConfiguracoes();
    setSearchParams();
  }, [obterExerciciosEVisaoAnalitica, obterConfiguracoes]);

  // set ordem inicial
  useEffect(() => {
    const colunaChave =
      configuracao?.Tipo === 2 ? obterColunaChave() : obterColunaLink();

    if (colunaChave && !listaColunaOrdem?.length) {
      obterOrdenacaoPadrao(colunaChave);
    }
    if (configuracao) obterChavesPeriocidadeDaConfiguracao();
  }, [obterColunaLink, obterColunaChave, configuracao]);

  // set periodos
  useEffect(() => {
    if (listaPeriodicidades?.length && periodicidadeSelecionada) {
      const periodicidade = listaPeriodicidades.find(
        x => x.Id === periodicidadeSelecionada
      );

      setListaPeriodos(periodicidade?.Periodos || []);
    }
  }, [periodicidadeSelecionada, listaPeriodicidades]);

  // set periodo
  useEffect(() => {
    configuraPeriodo();
  }, [
    exercicioSelecionado,
    listaExercicios,
    carregarPagina,
    periodicidadeSelecionada,
    periodoSelecionado,
    urlVisaoAnalitica,
    visao,
    listaPeriodicidades,
    configuracao,
  ]);

  // search da grid
  useEffect(() => {
    buscaDadosGrid();
  }, [buscaDadosGrid]);

  useEffect(() => {
    if (history.location.state?.pagina) {
      setPagina(history.location.state?.pagina);
    }
  }, [pagina]);

  const Grid = useMemo(() => {
    return configuracao?.VisaoColunas && moduloVisao?.Valores ? (
      <TableDinamic
        moduloVisao={moduloVisao}
        handleTableChange={handleTableChange}
        configuracao={configuracao}
        quantidadeRegistros={quantidadeRegistros}
        setQuantidadeRegistros={setQuantidadeRegistros}
        pagina={pagina}
        setPagina={setPagina}
        modulo={modulo}
        visao={visao}
        filtro={filtro}
        setFiltro={setFiltro}
        periodicidadeSelecionada={periodicidadeSelecionada}
        periodoSelecionado={periodoSelecionado}
        exercicioSelecionado={exercicioSelecionado}
        colunaFiltroVisaoAgrupamento={colunaFiltroVisaoAgrupamento}
        valorFiltroVisaoAgrupamento={valorFiltroVisaoAgrupamento}
        tipoValorFiltroVisaoAgrupamento={tipoValorFiltroVisaoAgrupamento}
        redirecionarDetalheVisaoAnalitica={redirecionarDetalheVisaoAnalitica}
        urlVisaoAnalitica={urlVisaoAnalitica}
        listaColunaOrdem={listaColunaOrdem}
        obterValoresChavesPeriodicidadePorLinha={
          obterValoresChavesPeriodicidadePorLinha
        }
        dataUltimaAtualizacao={dataUltimaAtualizacao}
        redirecionarVisaoAnalitica={redirecionarVisaoAnalitica}
        possuiArquivoDigital={possuiArquivoDigital}
        exibirAnexos={exibirAnexos}
        filtroPesquisaArquivosDigitais={filtroPesquisaArquivosDigitais}
        exibirArquivosForm={exibirArquivosForm}
        setFiltroPesquisaArquivosDigitais={setFiltroPesquisaArquivosDigitais}
        setExibirArquivosForm={setExibirArquivosForm}
        tableKey={tableKey}
        setDataFilterHeader={setDataFilterHeader}
        dataFilterHeader={dataFilterHeader}
        obterColunaChave={obterColunaChave}
        obterColunaLink={obterColunaLink}
      />
    ) : (
      <></>
    );
  }, [
    valorFiltroVisaoAgrupamento,
    colunaFiltroVisaoAgrupamento,
    listaColunaOrdem,
    configuracao,
    exercicioSelecionado,
    possuiArquivoDigital,
    filtro,
    modulo,
    moduloVisao,
    pagina,
    periodicidadeSelecionada,
    periodoSelecionado,
    quantidadeRegistros,
    urlVisaoAnalitica,
    visao,
    filtroPesquisaArquivosDigitais,
    exibirArquivosForm,
    exibirAnexos,
    dataUltimaAtualizacao,
  ]);

  useEffect(() => {
    async function obterTotalizadores() {
      const FiltroVisaoAgrupamento = {
        Campo: colunaFiltroVisaoAgrupamento,
        Valor: valorFiltroVisaoAgrupamento,
        TipoValor: tipoValorFiltroVisaoAgrupamento,
      };

      const data = {
        ChaveModulo: modulo,
        NomeVisao: visao,
        Filtros: filtro,
        Periodicidade: periodicidadeSelecionada,
        Periodo: periodoSelecionado,
        Exercicio: exercicioSelecionado,
        FiltroVisaoAgrupamento,
      };

      const ColunasTotalizadoras = await ModuloVisaoService.obterTotalizadores(
        data
      );

      setTotalizadores(ColunasTotalizadoras.data);
    }

    if (!validaPeriodicidade() || !exercicioSelecionado || !configuracao) {
      return;
    }

    obterTotalizadores();
  }, [
    colunaFiltroVisaoAgrupamento,
    valorFiltroVisaoAgrupamento,
    tipoValorFiltroVisaoAgrupamento,
    modulo,
    visao,
    configuracao,
    filtroDebounce,
    periodicidadeSelecionada,
    periodoSelecionado,
    exercicioSelecionado,
  ]);

  return (
    <>
      <Header configuracao={configuracao} />

      {totalizadores?.length > 0 && (
        <Totalizadores totalizadores={totalizadores} />
      )}

      <FiltroPeriodicidadeModuloDinamico
        filtro={filtro}
        pagina={pagina}
        setPagina={setPagina}
        configuracao={configuracao}
        obterColunaChave={obterColunaChave}
        obterColunaLink={obterColunaLink}
        setFiltro={setFiltro}
        InputCarregando={InputCarregando}
        periodicidadeSelecionada={periodicidadeSelecionada}
        setPeriocidadeSelecionada={setPeriocidadeSelecionada}
        listaPeriodicidades={listaPeriodicidades}
        periodoDesabilitado={periodoDesabilitado}
        periodoSelecionado={periodoSelecionado}
        setPeriodoSelecionado={setPeriodoSelecionado}
        listaPeriodos={listaPeriodos}
        exercicioSelecionado={exercicioSelecionado}
        setExercicioSelecionado={setExercicioSelecionado}
        listaExercicios={listaExercicios}
        isFiltroAvancadoHabilitado={isFiltroAvancadoHabilitado}
        history={history}
        obterOrdenacaoPadrao={obterOrdenacaoPadrao}
        setDataFilterHeader={setDataFilterHeader}
        dataFilterHeader={dataFilterHeader}
      />

      {Grid}

      <Row>
        <ButtonGoBack OnClick={voltarPaginaAnterior} />
      </Row>
    </>
  );
}

const ModuloDinamico = ({ history, match }) => {
  return (
    <ModuloDinamicoPage key={Math.random()} history={history} match={match} />
  );
};

export default ModuloDinamico;
